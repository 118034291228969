import React from "react"
import tw from "twin.macro"
import { Link } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Section from "@components/section"
import Container from "@components/container"
import { primaryCtaIh } from "@styles/ui"
import PrimaryCta from "@components/PrimaryCta"

// Assets
import xywavColourfulLogoImg from "@images/logos/xywav-logo.svg"
import IconArrow from "@images/ih/shared/icon_arrow.inline.svg"

const IHThankYouPage = ({ location }) => {
  return (
    <Layout location={location} headerType="ih" footerType="ih">
      {" "}
      <Seo
        title="IH Subscribe Thank You - XYWAV"
        description="Subscribe confirmation for XYWAV™ (calcium, magnesium, potassium, and sodium oxybates) oral solution updates. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <Section noHero bgColour={tw`bg-lilac`}>
        <Container isIh addedStyles={tw`xl:pb-28`}>
          <img
            src={xywavColourfulLogoImg}
            width="556px"
            alt=""
            tw="hidden xl:(block absolute -top-[6rem] -right-[15rem])"
          />
          <div css={[tw`pt-4 xl:(pt-0 pb-20)`]}>
            <h1 tw="font-cocon font-semibold text-studio text-[2rem] leading-[115%] mt-2.5 z-10 xl:(text-[2.75rem])">
              Thank you for <br tw="hidden xl:block" />
              signing&nbsp;up!
            </h1>
            <div tw="z-[1] relative mt-8 md:(mr-[120px]) xl:(mr-0 mt-5 max-w-[730px])">
              <p tw="pb-8 text-base font-light">
                Keep an eye out for helpful information about
                Idiopathic&nbsp;Hypersomnia&nbsp;(IH).
              </p>
              <PrimaryCta
                url="/idiopathic-hypersomnia/"
                addedStyles={primaryCtaIh}
                width={tw`!w-[10.313rem]`}
              >
                Return Home
              </PrimaryCta>
              <Link
                to="/idiopathic-hypersomnia/resources/"
                tw="relative block text-base text-coral-red font-black underline capitalize my-5 pr-20 lg:(my-6 pr-0 w-fit)"
              >
                Check out some helpful resources and videos
                <IconArrow tw="absolute w-[25px] h-[25px] right-0 top-1/2 -translate-y-1/2 lg:(-right-[2rem])" />
              </Link>
              <Link
                to="/idiopathic-hypersomnia/find-a-doctor/"
                tw="relative block text-base text-coral-red font-black underline capitalize pr-16 lg:(pr-0 w-fit)"
              >
                Find a doctor in your area to help you get started with XYWAV
                <IconArrow tw="absolute w-[25px] h-[25px] right-0 top-1/2 -translate-y-1/2 lg:(-right-[2rem])" />
              </Link>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default IHThankYouPage
